import mutations from './mutations';
import axios from "axios";
import { getHeaders } from '../../../config/auth0';

const state = () => ({
    categories: [],
    balance: null,
    loading: false,
    error: null,
});

const actions = {
    initCategories(context, query = {}) {
        //+ (organization_id ? `/${organization_id}` : '')
        //console.log(query)
        return axios.get(process.env.VUE_APP_API_URL + 'categories', {... {...getHeaders() }, ... { params: query } })
            .then((response) => {
                context.commit("initCategories", response.data)
                return response
            }).catch(e => e);
    },
    addCategory(context, category) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'categories', category, {...getHeaders() })
            .then((response) => {
                //if (response.data.info.status == 'success') {
                //context.commit("addCategory", response.data)
                //}
                return response;
            }).catch(e => e);
    },
    updateCategory(context, category) {
        return axios
            .patch(process.env.VUE_APP_API_URL + 'categories/' + category.id, category, {...getHeaders() })
            .then((response) => {
                context.commit("updateCategory", category);
                return response
            }).catch(e => e);
    },
    deleteCategory(context, id) {
        return axios
            .delete(process.env.VUE_APP_API_URL + 'categories/' + id, {...getHeaders() })
            .then((response) => {
                context.commit("deleteCategory", id);
            });
    },
    getBalance(context) {
        return axios
            .get(process.env.VUE_APP_API_URL + 'compaigns/balance', {... {...getHeaders() } })
            .then((response) => {
                context.commit("getBalance");
                return response
            }).catch(e => e);
    }
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};