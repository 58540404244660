export default {
    // initCampaignGroups(state, groups) {
    //     state.groups = groups;
    // },
    initCampaigns(state, campaigns) {
        state.campaigns = campaigns;
    },
    getCampaign(state, campaign) {
        state.campaign = campaign;
    },
    // getCampaignGroupMatters(state, matters) {
    //     state.matters = matters;
    // },
    addCampaign(state, campaign) {
        state.campaigns.push(campaign);
    },
    putCampaignBudget(state, budget) {
        state.budget = budget;
    },
    getCampaignBudget(state, words) {
        state.words = words;
    },
    putCampaignWordsMinus(state, words) {
        //state.words = words;
    },
    putCampaignWordsPlus(state, words) {
        //state.words = words;
    },
    putCampaignWordsPlusFixed(state, words) {
        //state.words = words;
    },
    // getCampaignWordsPlus(state, words) {
    //     //state.words = words;
    // },
    getCampaignStatWords(state, words) {
        //state.budget = budget;
    },
    // getCampaignWords(state, words) {
    //     //state.budget = budget;
    // },
    // getCampaignWordsStatistic(state, statistic) {
    //     //state.budget = budget;
    // },
    putCampaignMatters(state, matters) {
        //state.matters = matters
    },
    deleteCampaignMatters(state, matters) {
        //state.matters = matters
    },
    updateCampaign(state, campaign) {
        let index = state.campaigns.findIndex((c) => c.id == campaign.id);
        if (index > -1) {
            state.campaigns[index] = campaign;
        }
    },
    deleteCampaign(state, id) {
        let index = state.campaigns.findIndex((c) => c.id == id);
        if (index > -1) {
            state.campaigns.splice(index, 1);
        }
    }
};