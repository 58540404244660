export default {
  updateProducts(state, products) {
    state.products = products
  },
  updateKeyword(state, keyword) {
    state.keyword = keyword
  },
  updateType(state, type) {
    state.type = type
  },
  updateBids(state, bids) {
    state.bids = bids
  },
  updateRegion(state, city) {
    state.city = city
  },
  updateLoading(state, loading) {
    state.loading = loading
  },
  updateCodes(state, codes) {
    state.codes = codes
  },
  // updateMaxTimeDelivery(state, maxTimeDelivery) {
  //   state.maxTimeDelivery = maxTimeDelivery
  // }
};
