export default [
    {
        path: '/',
        name: 'mainpage',
        component: () => import( /* webpackChunkName: "MainPage" */ '@/view/pages/MainPage.vue'),
        meta: {
            title: 'Главная'
        }
    },
    {
        path: '/conferences',
        name: 'conferences',
        component: () => import( /* webpackChunkName: "Conferences" */ '@/view/pages/ConferencePage.vue'),
        meta: {
            title: 'Розыгрыши'
        }
    },
    {
        path: '/organizations',
        name: 'organizations',
        component: () => import( /* webpackChunkName: "Organizations" */ '@/view/pages/OrganizationsPage.vue'),
        meta: {
            title: 'Организации'
        }
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import( /* webpackChunkName: "Partners" */ '@/view/pages/Partners.vue'),
        meta: {
            title: 'Партнёрка'
        }
    },
    {
        path: '/tariffs',
        name: 'tariffs',
        component: () => import( /* webpackChunkName: "Tariffs" */ '@/view/pages/Tariffs.vue'),
        meta: {
            title: 'Тарифы'
        }
    },
    {
        path: '/actual_bids',
        name: 'actual_bids',
        component: () => import( /* webpackChunkName: "Actual_bids" */ '@/view/pages/ActualBids.vue'),
        meta: {
            title: 'Актуальные ставки'
        }
    },
    {
        path: '/products',
        name: 'products',
        component: () => import( /* webpackChunkName: "Products" */ '@/view/pages/ProductsPage.vue'),
        meta: {
            title: 'Мои товары'
        }
    },
    {
        path: '/check_positions',
        name: 'check_positions',
        component: () => import( /* webpackChunkName: "CheckPositions" */ '@/view/pages/CheckPositions.vue'),
        meta: {
            title: 'Проверка Позиций'
        }   
    },
    {
        path: '/adv_campaigns',
        name: 'adv_campaigns',
        children: [{
            path: '',
            name: 'adv_campaigns',
            component: () => import( /* webpackChunkName: "Adverts" */ '@/view/pages/advert/AdvCampaigns.vue'),
            meta: {
                title: 'Рекламные кампании'
            }
        },
        {
            path: '/campaign/edit/:id?',
            name: 'campaignEdit',
            component: () => import( /* webpackChunkName: "AdvertFormEdit" */ '@/view/pages/advert/AdvertFormEditNew.vue'),
            props: true,
            meta: {
                title: 'Управление кампанией'
            }
        },
        {
            path: '/campaign/edit_auto/:id?',
            name: 'campaignAuto',
            component: () => import( /* webpackChunkName: "AdvertFormEditAuto" */ '@/view/pages/advert/AdvertFormEditAuto.vue'),
            props: true,
            meta: {
                title: 'Управление кампанией'
            }
        },
        ]
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import( /* webpackChunkName: "Statistics" */ '@/view/pages/StatisticsPage.vue'),
        meta: {
            title: 'Статистика'
        }
    },
    {
        path: '/autosettings',
        name: 'autosettings',
        component: () => import( /* webpackChunkName: "AutoSettings" */ '@/view/pages/AutoSettings.vue'),
        meta: {
            title: 'Автонастройки'
        }
    },
    {
        path: '/user',
        name: 'profile',
        component: () => import( /* webpackChunkName: "User" */ '@/view/pages/ProfilePage.vue'),
        children: [
            {
                path: '/user/profile',
                name: 'profile',
                component: () => import( /* webpackChunkName: "Profile" */ '@/view/pages/profile/Profile.vue'),
                props: true,
                meta: {
                    title: 'Профиль'
                }
            },
            {
                path: '/user/password',
                name: 'password',
                component: () => import( /* webpackChunkName: "ChangePassword" */ '@/view/pages/profile/Password.vue'),
                meta: {
                    title: 'Смена пароля'
                }
            },
        ]
    },
    { 
        path: '/:pathMatch(.*)*', 
        component: () => import( /* webpackChunkName: "404" */ '@/view/pages/404.vue'),
        meta: {
            title: '404 - Страница не найдена'
        }
    }
];