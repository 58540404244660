export default {
    initOrganizations(state, organizations) {
        state.organizations = organizations;
    },
    addOrganization(state, organization) {
        state.organizations.push(organization);
    },
    updateOrganization(state, organization) {
        let index = state.organizations.findIndex((c) => c.id == organization.id);
        if (index > -1) {
            state.organizations[index] = organization;
        }
    },
    updateOrganizationApi(state, organization) {
        let index = state.organizations.findIndex((c) => c.id == organization.id);
        if (index > -1) {
            state.organizations[index] = organization;
        }
    },
    deleteOrganization(state, id) {
        let index = state.organizations.findIndex((c) => c.id == id);
        if (index > -1) {
            state.organizations.splice(index, 1);
        }
    },
    getOrganizationBalance(state, balance) {
        state.balance = balance;
    },
    getOrganizationGroups(state, groups) {
        state.groups = groups;
    },
    getOrganizationProducts(state, products) {
        state.products = products;
    },
};