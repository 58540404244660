export default {
  initPermissions(state, permissions) {
    state.permissions = permissions;
  },
  addPermission(state, permission) {
    state.permissions.push(permission);
  },
  updatePermission(state, permission) {
    let index = state.permissions.findIndex((c) => c.id == permission.id);
    if (index > -1) {
      state.permissions[index] = permission;
    }
  },
  deletePermission(state, id) {
    let index = state.permissions.findIndex((c) => c.id == id);
    if (index > -1) {
      state.permissions.splice(index, 1);
    }
  }
};
