import mutations from './mutations';
import axios from "axios";
import { getHeaders } from '../../../config/auth0';
//import qs from 'qs';

const state = () => ({
    keywords: [],
    hints: [],
    error: false,
    pending: false,
});

const actions = {
    hints(context, params = {}) {
        context.state.pending = true
        context.state.error = false
        //, paramsSerializer: (params) => qs.stringify(params, { encode: 'utf-8' }), 
        return axios.get(process.env.VUE_APP_API_URL + `keywords/hints`, {... {...getHeaders() }, ... { params } })
            .then((response) => {
                context.commit("hints", response.data.data)
                context.state.pending = false
                context.state.error = false
                return response
            }).catch(e => {
                context.state.error = true
                context.state.pending = false
                return e
            });
    },
    getStatWords(context, data) {
        return axios.get(process.env.VUE_APP_API_URL + `keywords/stat-words`, {...getHeaders(), ...{ params: data } })
            .then((response) => {
                context.commit("getCampaignStatWords", response.data)
                return response
            })//.catch(e => e);//Promise.reject(e)
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}