import mutations from './mutations';
import axios from "axios";
import { getHeaders } from '../../../config/auth0';

const state = () => ({
    organizations: [],
    balance: null,
    groups: null,
    products: null,
    loading: false,
    error: null,
    pending: false,
})
const preset = (context) => {
    context.state.pending = true
    context.state.error = false
}
const success = (context) => {
    context.state.pending = false
    context.state.error = false
}
const error = (context) => {
    context.state.error = true
    context.state.pending = false
}
const actions = {
    initOrganizations(context) {
        preset(context)
        return axios.get(process.env.VUE_APP_API_URL + 'organizations', {...getHeaders(), ...{'Accept': 'application/json',} }).then((response) => {
                context.commit("initOrganizations", response.data)
                success(context)
                return response
            }).catch(e => {
                error(context)
                return e
            });
    },
    addOrganization(context, organization) {
        preset(context)
        return axios
            .post(process.env.VUE_APP_API_URL + 'organizations', organization, {...getHeaders() })
            .then((response) => {
                //if (response.data.info.status == 'success') {
                //context.commit("addOrganization", response.data)
                //}
                success(context)
                return response;
            }).catch(e => {
                error(context)
                return e
            });
    },
    updateOrganization(context, organization) {
        preset(context)
        return axios
            .patch(process.env.VUE_APP_API_URL + 'organizations/' + organization.id, organization, {...getHeaders() })
            .then((response) => {
                context.commit("updateOrganization", organization);
                success(context)
                return response
            }).catch(e => {
                error(context)
                return e
            });
    },
    updateOrganizationApi(context, organization) {
        preset(context)
        return axios
            .patch(process.env.VUE_APP_API_URL + 'organizations/' + organization.id + '/api', organization, {...getHeaders() })
            .then((response) => {
                context.commit("updateOrganization", response.data);
                success(context)
                return response
            }).catch(e => {
                error(context)
                return e
            });
    },
    deleteOrganization(context, id) {
        preset(context)
        return axios
            .delete(process.env.VUE_APP_API_URL + 'organizations/' + id, {...getHeaders() })
            .then((response) => {
                context.commit("deleteOrganization", id);
                success(context)
                return response
            }).catch(e => {
                error(context)
                return e
            });
    },
    getOrganizationBalance(context, id) {
        return axios.get(process.env.VUE_APP_API_URL + `organizations/${id}/balance`, {...getHeaders() })
            .then((response) => {
                context.commit("getOrganizationBalance", response.data)
                return response
            })//.catch(e => e);
    },
    getOrganizationGroups(context, data) {
        return axios.get(process.env.VUE_APP_API_URL + `organizations/${data.id}/groups/${data.type}`, {...getHeaders() })
            .then((response) => {
                context.commit("getOrganizationGroups", response.data)
                return response
            })//.catch(e => e);
    },
    getOrganizationBrands(context, id) {
        return axios.get(process.env.VUE_APP_API_URL + `organizations/${id}/brands`, {...getHeaders() })
            .then((response) => {
                //context.commit("getOrganizationGroups", response.data)
                return response
            })//.catch(e => e);
    },
    getOrganizationProducts(context, data) {
        return axios.get(process.env.VUE_APP_API_URL + `organizations/${data.organizationId}/groups/${data.groupId}/products`, {...getHeaders(), ... { params: { productIds: data.productIds } } })
            .then((response) => {
                context.commit("getOrganizationProducts", response.data)
                return response
            })//.catch(e => e);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};