export default {
    setUserData(state, user) {
        state.userData = user;
    },
    setUserHaveOrg(state, haveOrg) {
        state.haveOrg = haveOrg;
    },
    setUserOrgsCounts(state, orgs_counts) {
        state.orgs_counts = orgs_counts;
    },
    setUserExpiredAt(state, expiredAt) {
        state.expiredAt = expiredAt;
    },
    //
    loginBegin(state) {
        state.login = true;
    },
    loginSuccess(state, data) {
        state.loading = false;
        state.login = data;
    },

    loginErr(state, err) {
        state.loading = false;
        state.error = err;
    },
    setErrors(state, err) {
        state.loading = false;
        state.error = err;
    },
    logoutBegin(state) {
        state.loading = true;
    },

    logoutSuccess(state, data) {
        state.loading = false;
        state.login = data;
    },

    logoutErr(state, err) {
        state.loading = false;
        state.error = err;
    },
};