import Cookies from 'js-cookie';
export const auth0options = {
    theme: {
        logo: 'https://demo.jsnorm.com/react/strikingdash/static/media/Logo_Dark.9ef25a33.svg',
        primaryColor: '#5F63F2',
        labeledSubmitButton: true,
    },
    avatar: null,
    rememberLastLogin: false,
    auth: {
        redirect: false,
    },
    languageDictionary: {
        title: 'Sign in with Auth0',
    },
    popupOptions: { width: 460, height: 506, left: 200, top: 300 },
};

export const getHeaders = () => {
    return { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get("authToken")}` } }
}
export const getHeadersUploader = () => {
    return { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${Cookies.get("authToken")}` } }
}
export const authToken = { Authorization: `Bearer ${Cookies.get("authToken")}` }