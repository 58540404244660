import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import headerSearchData from './modules/headerSearch/actionCreator';
import auth from './modules/authentication/actionCreator';
import ecommerce from './modules/ecommerce/product/actionCreator';
import organization from './modules/organization/actionCreator';
import product from './modules/product/actionCreator';
import permission from './modules/permission/actionCreator';
import actualBids from './modules/actualbids/actionSearch';
import crud from './modules/crud/actionCreator';
import campaign from './modules/campaign/actionCreator';
import category from './modules/category/actionCreator';
import keyword from './modules/keyword/actionCreator';
export default createStore({
    modules: {
        permission,
        themeLayout,
        headerSearchData,
        auth,
        ecommerce,
        organization,
        product,
        actualBids,
        crud,
        campaign,
        category,
        keyword,
    },
});