import mutations from './mutations';
import axios from "axios";
import { getHeaders } from '../../../config/auth0';

const state = () => ({
    campaign: {},
    campaigns: [],
    budget: null,
    //groups: [],
    words: [],
    matters: [],
    loading: false,
    error: null,
});

const actions = {
    initCampaigns(context, query = {}) {
        return axios.get(process.env.VUE_APP_API_URL + 'campaigns', {... {...getHeaders() }, ... { params: query } })
            .then((response) => {
                context.commit("initCampaigns", response.data)
                return response
            })//.catch(e => e);
    },
    getCampaign(context, id) {
        return axios.get(process.env.VUE_APP_API_URL + `campaigns/` + id, {...getHeaders() })
            .then((response) => {
                context.commit("getCampaign", response.data)
                return response
            })//.catch(e => Promise.reject(e));
    },
    putCampaignBudget(context, params) {
        return axios.put(process.env.VUE_APP_API_URL + `campaigns/${params.id}/budget`, params, {...getHeaders() })
            .then((response) => {
                context.commit("putCampaignBudget", response.data)
                return response
            }).catch(e => e);
    },
    putCampaignStatus(context, params) {
        return axios.put(process.env.VUE_APP_API_URL + `campaigns/${params.campaignId}/status/${params.statusId}`, params, {...getHeaders() })
            .then((response) => {
                //context.commit("putCampaignBudget", response.data)
                return response
            }).catch(e => Promise.reject(e));
    },
    getCampaignBudget(context, id) {
        return axios.get(process.env.VUE_APP_API_URL + `campaigns/${id}/budget`, {...getHeaders() })
            .then((response) => {
                context.commit("getCampaignBudget", response.data)
                return response
            })//.catch(e => e);//Promise.reject(e)
    },
    putCampaignWordsMinus(context, params) {
        return axios.put(process.env.VUE_APP_API_URL + `campaigns/${params.id}/words/minus`, params, {...getHeaders() })
            .then((response) => {
                context.commit("putCampaignWordsMinus", response.data)
                return response
            }).catch(e => e);
    },
    putCampaignWordsPlus(context, params) {
        return axios.put(process.env.VUE_APP_API_URL + `campaigns/${params.id}/words/plus`, params, {...getHeaders() })
            .then((response) => {
                context.commit("putCampaignWordsPlus", response.data)
                return response
            }).catch(e => e);
    },
    putCampaignWordsPlusFixed(context, params) {
        console.log('actionCreator', params)
        return axios.get(process.env.VUE_APP_API_URL + `campaigns/${params.id}/words/plus-fixed?fixed=${params.fixed}`, {...getHeaders() })
            .then((response) => {
                context.commit("putCampaignWordsPlusFixed", response.data)
                return response
            }).catch(e => e);
    },
    getCampaignStatWords(context, data) {
        return axios.get(process.env.VUE_APP_API_URL + `campaigns/${data.id}/words/stat-words?show_bids=${data?.show_bids}&region=${data?.region}`, {...getHeaders() })
            .then((response) => {
                context.commit("getCampaignStatWords", response.data)
                return response
            })//.catch(e => e);//Promise.reject(e)
    },
    getCampaignStatGroups(context, id) {
        return axios.get(process.env.VUE_APP_API_URL + `campaigns/${id}/words/stat-groups`, {...getHeaders() })
            .then((response) => {
                //context.commit("getCampaignStatWords", response.data)
                return response
            })//.catch(e => e);//Promise.reject(e)
    },
    putCampaignBidTask(context, params) {
        return axios.put(process.env.VUE_APP_API_URL + `campaigns/${params.id}/bid-task`, params, {...getHeaders() })
            .then(response => response).catch(e => e);
    },
    getCampaignBidTask(context, id) {
        return axios.get(process.env.VUE_APP_API_URL + `campaigns/${id}/bid-task`, {...getHeaders() })
            .then(response => response).catch(e => e);//Promise.reject(e)
    },
    putCampaignMatters(context, data) {
        return axios.put(process.env.VUE_APP_API_URL + `campaigns/${data.id}/product`, data, {...getHeaders() })
            .then((response) => {
                context.commit("putCampaignMatters", response.data)
                return response
            }).catch(e => e);
    },
    deleteCampaignMatters(context, data) {
        return axios.delete(process.env.VUE_APP_API_URL + `campaigns/${data.id}/product`, {... {...getHeaders() }, ... { data } })
            .then((response) => {
                context.commit("deleteCampaignMatters", response.data)
                return response
            }).catch(e => e);
    },
    addCampaign(context, campaign) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'campaigns', campaign, {...getHeaders() })
            .then((response) => {
                //if (response.data.info.status == 'success') {
                //context.commit("addCampaign", response.data)
                //}
                return response;
            }).catch(e => e);
    },
    addCampaignMulty(context, campaign) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'campaigns/multy', campaign, {...getHeaders() })
            .then((response) => response).catch(e => e);
    },
    addCampaignMultyBids(context, bids) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'campaigns/multy/bids', { bids }, {...getHeaders() })
            .then((response) => response).catch(e => e);
    },
    updateCampaign(context, campaign) {
        return axios
            .patch(process.env.VUE_APP_API_URL + `campaigns/${campaign.id}`, campaign, {...getHeaders() })
            .then((response) => {
                context.commit("updateCampaign", campaign);
                return response
            }).catch(e => e);
    },
    deleteCampaign(context, id) {
        return axios
            .delete(process.env.VUE_APP_API_URL + 'campaigns/' + id, {...getHeaders() })
            .then((response) => {
                context.commit("deleteCampaign", id);
            });
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};