export default {
    initProducts(state, products) {
        state.products = products;
    },
    addProduct(state, product) {
        state.products.push(product);
    },
    updateProduct(state, product) {
        let index = state.products.findIndex((c) => c.id == product.id);
        if (index > -1) {
            state.products[index] = product;
        }
    },
    deleteProduct(state, id) {
        let index = state.products.findIndex((c) => c.id == id);
        if (index > -1) {
            state.products.splice(index, 1);
        }
    }
};