import mutations from './mutations';
import Cookies from 'js-cookie';
import axios from "axios";
import { getHeaders } from '../../../config/auth0';

const state = () => ({
    login: Cookies.get('logedIn'),
    loading: false,
    error: null,
    haveOrg: null,
    orgs_counts: null,
    expiredAt: null,
});

const actions = {
    getUserData({ commit }) {
        return axios
            .get(process.env.VUE_APP_API_URL + "user", {...getHeaders() })
            .then(response => {
                commit("setUserData", response.data);
                commit("setUserHaveOrg", response.data.haveOrg ?? false);
                commit("setUserOrgsCounts", response.data.orgs_counts ?? false);
                commit("setUserExpiredAt", response.data.expired_at == null ? null : new Date(response.data.expired_at));
                return response
            }).catch(e => Promise.reject(e));
            /*
            .catch((e) => {
                console.error('Auth error', e)
                return e
            })
            */
    },
    updateUserData({ commit }, data) {
        return axios
            .put(process.env.VUE_APP_API_URL + `users`, data, {...getHeaders() })
            .then(response => {
                commit("setUserData", response.data);
                return response
            })
            .catch((e) => e)
    },
    changeUserPassword({ commit }, data) {
        return axios.put(process.env.VUE_APP_API_URL + `users/change-password`, data, {...getHeaders() })
            .then(response => response).catch((e) => e)
    },
    sendLoginRequest({ commit }, data) {
        commit("setErrors", {}, { root: true });
        return axios
            .post(process.env.VUE_APP_API_URL + "login", data)
            .then(response => {
                commit("setUserData", response.data);
                commit('loginBegin');
                commit('loginSuccess', true);
                //
                Cookies.set("authToken", response.data.token);
                Cookies.set('logedIn', true);
                //
                return response
            });
    },
    sendRegisterRequest({ commit }, data) {
        commit("setErrors", {}, { root: true });
        return axios
            .post(process.env.VUE_APP_API_URL + "register", data)
            .then(response => {
                commit("setUserData", response.data);
                commit('loginBegin');
                commit('loginSuccess', true);
                Cookies.set("authToken", response.data.token);
                Cookies.set('logedIn', true);
                return response;
            }).catch(error => {
                return error;
                //console.log("ERRRR:: ", error.response.data);
            });
    },
    sendLogoutRequest({ commit }) {
        commit("setUserData", null);
        commit('logoutBegin');
        commit('logoutSuccess', null);
        //
        Cookies.remove('authToken');
        Cookies.remove('logedIn');
        // return axios.post(process.env.VUE_APP_API_URL + "logout").then(() => {
        //   commit("setUserData", null);
        //   commit('logoutBegin');
        //   commit('logoutSuccess', null);
        //   //
        //   Cookies.remove('authToken');
        //   Cookies.remove('logedIn');
        // });
    },
    sendVerifyResendRequest() {
        return axios.get(process.env.VUE_APP_API_URL + "email/resend");
    },
    sendVerifyRequest({ dispatch }, hash) {
        return axios
            .get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
            .then(() => {
                dispatch("getUserData");
            });
    },
    //
    async login({ commit }) {
        try {
            commit('loginBegin');
            Cookies.set('logedIn', true);
            return commit('loginSuccess', true);
        } catch (err) {
            commit('loginErr', err);
        }
    },
    async logOut({ commit }) {
        try {
            commit('logoutBegin');
            Cookies.remove('logedIn');
            commit('logoutSuccess', null);
        } catch (err) {
            commit('logoutErr', err);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};