export default {
    initCategories(state, categories) {
        state.categories = categories;
    },
    addCategory(state, product) {
        state.categories.push(product);
    },
    updateCategory(state, product) {
        let index = state.categories.findIndex((c) => c.id == product.id);
        if (index > -1) {
            state.categories[index] = product;
        }
    },
    deleteCategory(state, id) {
        let index = state.categories.findIndex((c) => c.id == id);
        if (index > -1) {
            state.categories.splice(index, 1);
        }
    },
    getBalance(state, balance) {
        state.balance = balance;
    },
};