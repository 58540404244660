import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';

const routes = [{
        name: 'Admin',
        path: '/',
        component: () =>
            import ( /* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
        children: [...adminRoutes],
        meta: { auth: false },
    },
    {
        name: 'Auth',
        path: '/auth',
        component: () =>
            import ( /* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
        children: [...authRoutes],
        meta: { auth: true, title: 'Авторизация' },
    },
    {
        name: 'adminauth',
        path: '/secret/logto/kunzhut',
        component: () =>
            import ( /* webpackChunkName: "secret" */ '@/view/pages/Secret.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(
        process.env.NODE_ENV === 'production' ?
        process.env.VUE_APP_SUB_ROUTE ?
        process.env.VUE_APP_SUB_ROUTE :
        process.env.BASE_URL :
        process.env.BASE_URL,
    ),
    linkExactActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.path == '/secret/logto') {
        next()
    } else {
        if (to.meta.auth && store.state.auth.login) {
            next({ to: '/' });
        } else if (!to.meta.auth && !store.state.auth.login) {
            next({ name: 'login' });
        } else {
            // Get the page title from the route meta data that we have defined
            // See further down below for how we setup this data
            const title = to.meta.title
            //Take the title from the parameters
            const titleFromParams = to.params.pageTitle;
            // If the route has a title, set it as the page title of the document/page
            if (title) {
                document.title = title
            }
            // If we have a title from the params, extend the title with the title
            // from our params
            if (titleFromParams) {
                document.title = `${titleFromParams} - ${document.title}`;
            }
            // Continue resolving the route
            next()
        }
    }
    
});

export default router;