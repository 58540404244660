import { theme, darkTheme } from './theme/themeVariables';
import Cookies from 'js-cookie';

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  auth0: false,
  getAuthHeader: ()=>{
    return {
      headers: { Authorization: `Bearer ${Cookies.get("authToken")}`}
    };
  },
  password: {
    min: 6,
    max: 30,
  }
};

export default config;
