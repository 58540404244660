import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
import './static/css/custom-ui.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/components/custom';
import '@/core/components/style';
//import 'ant-design-vue/dist/antd.css';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import { LicenseManager } from  'ag-grid-enterprise'

LicenseManager.setLicenseKey("CompanyName=Pressory,LicensedApplication=Pressory,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026074,ExpiryDate=6_March_2023_[v2]_MTY3ODA2MDgwMDAwMA==35313d3bc79f875d0220cfce40b29f1c");

app.config.productionTip = true;
app.use(VueApexCharts)
app.use(vClickOutside)
app.use(store);
app.use(router);
app.mount('#app');